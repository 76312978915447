import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import Stack from 'ui-kit/Stack';

import { Button, Typography } from 'ui-kit';
import ConfirmModal from 'components/ConfirmModal';
import { useFormContext, useWatch } from 'react-hook-form';
import { message } from 'antd';
import { createStores } from 'api/store';

export interface ICreateStoresButtonConfirmModal {
  ownerId: number;
  setIsCreatingStores: React.Dispatch<React.SetStateAction<boolean>>;
  setMassStoreRequestId: React.Dispatch<React.SetStateAction<number | null>>;
}

const CreateStoresButtonConfirmModal = ({
  ownerId,
  setIsCreatingStores,
  setMassStoreRequestId,
}: ICreateStoresButtonConfirmModal) => {
  const [isOpen, setIsOpen] = useState(false);

  const { control } = useFormContext();

  const formData = useWatch({ control, name: 'listings' });

  const selectedListings = useMemo(
    () => formData?.filter((listing) => listing.selected) || [],
    [formData],
  );

  const createButtonContent = useMemo(
    () => `Create ${selectedListings.length} Store${selectedListings.length === 1 ? '' : 's'}`,
    [selectedListings],
  );

  const isDisabled = useMemo(() => selectedListings.length === 0, [selectedListings]);

  const submit = useCallback(() => {
    setIsCreatingStores(true);
    createStores({ owner: ownerId, stores: selectedListings })
      .then((response) => {
        if (response.ok) {
          setMassStoreRequestId(response.data?.massStoreRequestId);
        } else {
          setIsCreatingStores(false);
          message.error(
            response?.errors?.fields?.[0] || 'Something went wrong creating your stores.',
            10,
          );
        }
      })
      .finally(() => setIsOpen(false));
  }, [ownerId, selectedListings, setIsCreatingStores, setMassStoreRequestId]);

  return (
    <>
      <Button variant="contained" disabled={isDisabled} onClick={() => setIsOpen(true)}>
        {createButtonContent}
      </Button>

      <ConfirmModal
        visible={isOpen}
        confirmBtnType="primary"
        confirmText="Create"
        cancelText="Cancel"
        onCancel={() => setIsOpen(false)}
        onConfirm={submit}
        destroyOnClose
      >
        <Typography variant="h4">Create stores</Typography>
        <Stack
          flexDirection="column"
          alignItems="flex-start"
          style={{ textAlign: 'left', marginTop: '1rem' }}
          gap="8px"
        >
          <Typography variant="body1">
            If store address or postal code is missing, store will default to USA, $USD, and
            nationally available vendors.
          </Typography>
          <Typography variant="body1">
            If the store description is too long it will be truncated after 500 characters.
          </Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Update info per store using Manage &gt; Edit.
          </Typography>
        </Stack>
      </ConfirmModal>
    </>
  );
};
export default CreateStoresButtonConfirmModal;
